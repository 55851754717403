import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import MarkdownWrapper from '../../../../components/MarkdownWrapper';
import Layout from '../../../../components/Layout';
export const _frontmatter = {
  "title": "Add-exercise design doc",
  "path": "/knowledge/c0d3/docs",
  "date": "2022-08-08T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout title={props.pageContext.frontmatter.title} location={props.path} mdxType="Layout">
      <MarkdownWrapper mdxType="MarkdownWrapper">
        <h1 {...{
          "id": "exercise-page-design-1975",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h1" {...{
            "href": "#exercise-page-design-1975",
            "aria-label": "exercise page design 1975 permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Exercise page design (#1975)`}</h1>
        <h2 {...{
          "id": "components",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h2" {...{
            "href": "#components",
            "aria-label": "components permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Components`}</h2>
        <h3 {...{
          "id": "dropdown-to-switch-the-module",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#dropdown-to-switch-the-module",
            "aria-label": "dropdown to switch the module permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Dropdown to switch the module`}</h3>
        <p><img parentName="p" {...{
            "src": "media/16595322784961/16595323424364.jpg",
            "alt": null
          }}></img></p>
        <p>{`If there's a `}<inlineCode parentName="p">{`moduleId`}</inlineCode>{` in the URL params, set it as the default module. Else, query a list of modules for `}<inlineCode parentName="p">{`lessonSlug`}</inlineCode>{` and list them. Set the first module as the default one.`}</p>
        <p><strong parentName="p">{`Shape:`}</strong></p>
        <pre><code parentName="pre" {...{
            "className": "language-tsx"
          }}>{`// Get moduleId and lessonSlug from URL
const { moduleId } = router.query

// States
const [activeModule, setActiveModule] = useState(moduleId)
const filteredModule = modules.filter(
    ... => module.lesson.slug === lessonSlug
)

<DropdownMenu ...  />

// DropdownMenu example Impl
const DropDownMenu = (filteredModules, activeModule, setActiveModule) => {
  return (
      <Dropdown>
        <Dropdown.Toggle>
          {activeModule.title}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {filteredModules?.map((module, index) => (
            <Dropdown.Item onClick={() => setActiveModule(module)}>
              {module.title}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
  )
}
`}</code></pre>
        <p><strong parentName="p">{`Questions`}</strong>{`:`}</p>
        <ul>
          <li parentName="ul"><del parentName="li">{`What happens if the `}<inlineCode parentName="del">{`moduleId`}</inlineCode>{` in the URL is invalid?`}</del></li>
          <li parentName="ul">{`What if there are 100 modules?`}
            <ul parentName="li">
              <li parentName="ul">{`How to list them?`}
                <ul parentName="li">
                  <li parentName="ul">{`Add an input where they can see a list of modules and search for one.`}</li>
                </ul>
              </li>
            </ul>
          </li>
          <li parentName="ul"><del parentName="li">{`What if the module got renamed while another eng is trying to add an exercise to it?`}</del>
            <ul parentName="li">
              <li parentName="ul">{`We use the `}<inlineCode parentName="li">{`moduleId`}</inlineCode>{` to find the right module to add an exercise to.`}</li>
            </ul>
          </li>
        </ul>
        <h3 {...{
          "id": "exaplantion--question-inputs",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#exaplantion--question-inputs",
            "aria-label": "exaplantion  question inputs permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Exaplantion & Question inputs`}</h3>
        <p><img parentName="p" {...{
            "src": "media/16595322784961/16595345483809.jpg",
            "alt": null
          }}></img>{`
`}<img parentName="p" {...{
            "src": "media/16595322784961/16595363160396.jpg",
            "alt": null
          }}></img></p>
        <p>{`Use `}<a parentName="p" {...{
            "href": "https://github.com/garageScript/c0d3-app/blob/f50e1dbd245678b078d55cbef5d130ec0f4fd9be/components/MdInput.tsx"
          }}>{`mdInput`}</a>{`. There will be a state that will be updated with the value the input has.`}</p>
        <h3 {...{
          "id": "answer-radio-inputs",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#answer-radio-inputs",
            "aria-label": "answer radio inputs permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Answer radio inputs`}</h3>
        <p><img parentName="p" {...{
            "src": "media/16595322784961/16598772910130.jpg",
            "alt": null
          }}></img></p>
        <p><strong parentName="p">{`Shape:`}</strong></p>
        <p>{`Regular input`}</p>
        <p><del parentName="p">{`Look into using formik `}<a parentName="del" {...{
              "href": "https://formik.org/docs/api/useFormik"
            }}><inlineCode parentName="a">{`useFormik`}</inlineCode>{` hook`}</a>{`. It'll simplify the code as with the current shape, we'll have to pass 3 `}<inlineCode parentName="del">{`setState`}</inlineCode>{` actions, and 3 `}<inlineCode parentName="del">{`reactive`}</inlineCode>{` values.`}</del></p>
        <p><strong parentName="p">{`Questions:`}</strong></p>
        <ul>
          <li parentName="ul"><del parentName="li">{`How to figure out if no answer was chosen as the correct answer?`}</del>
            <ul parentName="li">
              <li parentName="ul">{`Where should the error message appear?`}
                <ul parentName="li">
                  <li parentName="ul">{`Above the inputs`}</li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <h3 {...{
          "id": "submit-button",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#submit-button",
            "aria-label": "submit button permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Submit button`}</h3>
        <p><img parentName="p" {...{
            "src": "media/16595322784961/16595364402810.jpg",
            "alt": null
          }}></img></p>
        <p>{`Once clicked, it'll run `}<a parentName="p" {...{
            "href": "https://github.com/garageScript/c0d3-app/blob/af998122ba4e170a4d158e8478b9ed38fa3f192d/graphql/resolvers/exerciseCrud.ts#L25"
          }}>{`useAddExercise`}</a>{` with the inputs values. An object could be used to hold all the values.`}</p>
      </MarkdownWrapper>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      